/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../../Navbar";
import Header from "../../Header";
import baseUrl from "../../BaseUrl";
import Loader from "../../Loader";
import { useNavigate, Link, useLocation} from 'react-router-dom';

const Step1 = ({ onNext,cid, successNumberList, setDmtNumberList, role,token, formData, setFormData, setDMTList }) => {
    
  
    const [popupOpen, setPopupOpen] = useState(false);
    // const [updatedNumber, setUpdatedNumber] = useState([]);
    const [selectAllNumber, setSelectAllNumber] = useState(false);
    const [selectedNumber, setSelectedNumber] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const navigate = useNavigate();
    if(!successNumberList){
        window.location.href = '/dashboard';
    }
   
    const handleSwitchSelectAll = (e) => {
        if (selectAllNumber) {
            // const updatedArray = successNumberList.map(obj => ({ ...obj, ["status"]: 0 }));
            setSelectedNumber([]);
            // setUpdatedNumber(updatedArray);
        } else {
            // const updatedArray = successNumberList.map(obj => ({ ...obj, ["status"]: 1 }));
            setSelectedNumber(successNumberList); // Select all rows
            // setUpdatedNumber(updatedArray);
        }
        setSelectAllNumber(!selectAllNumber);
    };

    const handleToggleSelect = (index) => {
        const updatedSelectedRows = selectedNumber.includes(successNumberList[index])
            ? selectedNumber.filter(item => item !== successNumberList[index]) // Deselect
            : [...selectedNumber, successNumberList[index]]; // Select

        // const updateArray = successNumberList.map((obj) => {
        //     const existsInUpdated = updatedSelectedRows.some(updatedObj => updatedObj.tn_number === obj.tn_number);
        //     if(existsInUpdated){
        //         obj.status = 1;
        //     }else{
        //         obj.status = 0;
        //     }
        //     return obj;
        // })
        // setUpdatedNumber(updateArray);
        setSelectedNumber(updatedSelectedRows);

    };

    const handleNext = async(e) => {
        e.preventDefault();
        if(selectedNumber.length == 0){
            alert("Please select atleast one number");
            return;
        }else{
            setDmtNumberList(selectedNumber);
            onNext();
        }
    }

 
    return (
        <>
                {popupOpen && (
                     <Loader /> 
                ) }
                
                    <div className="fullrow">
                        <div className="row">
                            <div className="col-6">
                                <div className="left-title">
                                    <h1>Add Digit Mapping</h1>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="addnew-button">
                                    <Link to="/view-order"><span>&larr;</span> Back</Link>
                                </div>
                                
                            </div>
            
                        </div>
                        
                        {successNumberList.length > 0 && <div className="common-white-shadow-background setting-section tableinput-section order-number-four-table">
                            <div className="row footer-row">
                                <div className="order-con-title footer-row-left">
                                    Select a number to add DMT
                                </div>
                                <div className="footer-row-right">
                                    <a href="#" name="remove_all_import" className={buttonDisabled ? 'next disabled-link newrow' : 'next newrow'} onClick={(e) => {handleNext(e)}} >Next</a>
                                </div>
                            </div>
                            <table className="tablebox new-number-two-setup">
                                <thead>
                                    <tr>
                                        <th><input type="checkbox" className="newrow checkboxAll"  onChange={handleSwitchSelectAll} checked={selectAllNumber} /></th>
                                        <th>Tn Number</th>
                                        <th>Number Type</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {successNumberList.map((dmt, index) => (
                                        <tr key={index}>
                                        
                                            <td><input type="checkbox"  checked={selectedNumber.includes(dmt)} onChange={() => handleToggleSelect(index)} /></td>
                                            <td>{dmt.tn_number}</td>
                                            <td>{dmt.did_order == 0 ? "Did" : "Toll Free"}</td>
                                    
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>}
                    </div>
           </>
        
    )
}
 
const Step2 = ({ onNext,cid, checkAdvanceDigitMapping, dmtNumberList,role,token, formData, setFormData, setDMTList, setExistingDMTList }) => {
    
  
    const [company, setCompany] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const [errors, setErrors] = useState([]);
    const [isSuccess, setIsSuccess] = useState(false);
    const [msg, setMsg] = useState('');

    const navigate = useNavigate();
     
      useEffect(() => {
        if (token) {
          getUserCompany();
        }
      }, [token]);
      
   

    useEffect(() => {
        if(role > 2){
            setFormData((prevData) => ({
                ...prevData,
                cid: cid,                
            }));
        }
    }, [role]);

    useEffect(() => {
        if(dmtNumberList.length > 0 && company.length > 0){
            const list = dmtNumberList.map(item => {
                return{
                tn_number: item.tn_number,
                alias: '',
                table_id: item.did_order == 0 ? 2 : 1,
                translation_type: '',
                original_ani: '',
                ani_min_length:0,
                ani_max_length:40,
                original_dnis:'',
                dnis_min_length:0,
                dnis_max_length:40,
                translated_ani:'',
                translated_dnis:'',
                ani_action:'1',
                dnis_action:'1'
            }});
            setFormData(prevFormData => ({
                ...prevFormData,
                dmtList: list
            }));
        }
    }, [dmtNumberList, company]);

    useEffect(() => {
        if(formData.cid && formData.dmtList.length > 0){
            handleDmtList();
        }
    }, [formData.mapping_type]);

    const getUserCompany = async () => {
        
        const response = await axios.get(`${baseUrl}/active-user-company`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        // console.log(response.data);
        let allArray;
        if(role <= 2){
            // allArray = response.data.slice(1);
            allArray = response.data.filter(item => item.id !== 1);
        }else{
            allArray = response.data;
        }
        
        setCompany(allArray);
    }
    

    
    const formRef = useRef(null);

    const handleDmtList = () => {
        const list = formData.dmtList.map(item => ({
            tn_number: item.tn_number,
            alias: handleSelectAliasForNumber(item.tn_number),
            table_id: item.table_id,
            translation_type: item.translation_type ?? '',
            original_ani: item.original_ani ?? '',
            ani_min_length: item.ani_min_length ?? 0,
            ani_max_length: item.ani_max_length ?? 40,
            original_dnis: item.original_dnis ?? '',
            dnis_min_length: item.dnis_min_length ?? 0,
            dnis_max_length: item.dnis_max_length ?? 40,
            translated_ani: item.translated_ani ?? '',
            translated_dnis: item.translated_dnis ?? '',
            ani_action: item.ani_action ?? '1',
            dnis_action: item.dnis_action ?? '1'
        }));
        setFormData(prevFormData => ({
            ...prevFormData,
            dmtList: list
        }));
    }

    const handleMultipleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            dmtList : prevData.dmtList.map(item => {
                let obj = {
                    ...item,
                    [name]: value
                }
                if(name === "translation_type"){ 
                    if(value == 1){
                        obj.original_ani = item.tn_number;
                        obj.original_dnis = "ANY";
                        obj.ani_action = 1;
                        obj.dnis_action = 2;
            
                    }else if(value == 2){
                        obj.original_ani = "ANY";
                        obj.original_dnis = item.tn_number;
                        obj.ani_action = 2;
                        obj.dnis_action = 1;
                    }
                    
                }
                return obj;
            })       
        }));   
    }

    const handleDMTTypeChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
            dmtList : prevData.dmtList.map(item => {
                let obj = {
                    ...item
                }
                if(prevData.methodType == 1 && value == 1){
                    obj.translation_type = '';
                    obj.original_ani = item.tn_number;
                    obj.original_dnis = "ANY";
                    obj.ani_action = 1;
                    obj.dnis_action = 2;
        
                }else if(prevData.methodType == 1 && value == 2){
                    obj.original_ani = "ANY";
                    obj.original_dnis = item.tn_number;
                    obj.ani_action = 2;
                    obj.dnis_action = 1;
                }else if(prevData.methodType == 0){
                    obj.translation_type = '2';
                    obj.original_ani = "ANY";
                    obj.original_dnis = item.tn_number;
                    obj.ani_action = 2;
                    obj.dnis_action = 1;
                }
                return obj;
            })       
        }));  
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if(name === "methodType"){
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
                mapping_type : "",
                dmtList : prevData.dmtList.map(item => {
                    let obj = {
                        ...item
                    }
                    obj.alias=  '';
                    obj.table_id = item.did_order == 0 ? 2 : 1;
                    obj.translation_type ='';
                    obj.original_ani = '';
                    obj.ani_min_length =0;
                    obj.ani_max_length =40;
                    obj.original_dnis ='';
                    obj.dnis_min_length =0;
                    obj.dnis_max_length =40;
                    obj.translated_ani ='';
                    obj.translated_dnis ='';
                    obj.ani_action ='1';
                    obj.dnis_action = '1';
                    return obj;
                })           
            }));
        }else{
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,                
            }));
        }
           
    };

    const handleSelectAliasForNumber = (data) => {
        
        const name = company.find((item) => item.id == formData.cid).short_name;
        const mapping_type = formData.mapping_type;
        let alias;
        if(mapping_type == 1){
            alias = name+"_"+data;
        }else if(mapping_type == 2){
            alias = name.substring(0, 22)+"_OFFNET"+"_"+data;
        }
        return alias;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const checkError = await validationCheck(formData);
        if (checkError.length > 0) {
            return; // Prevent form submission if there are errors
        }else{
            try {
                setPopupOpen(true);
                const aniNumberList = formData.dmtList.filter(record => record.translation_type == "1").map(item => item.tn_number);
                const dnisNumberList = formData.dmtList.filter(record => (record.translation_type == "2" || formData.mapping_type == 2)).map(item => item.tn_number);
       
                const response = await axios.post(`${baseUrl}/get-digitmapping-tnnumber`, {aniNumberList : aniNumberList,dnisNumberList:dnisNumberList , company_id: formData.cid},{
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const existingResponse = await axios.post(`${baseUrl}/get-portal-existing-dmt-tnnumber`, {aniNumberList : aniNumberList,dnisNumberList:dnisNumberList , company_id: formData.cid},{
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                const existingDMT = existingResponse?.data ?? [];

                const updatedArray = response.data[0]?.data.map(obj => ({ ...obj, ["status"]: 1 })) ?? [];
                if(updatedArray.length > 0 || existingDMT.length > 0){
                    setDMTList(updatedArray);
                    setExistingDMTList(existingDMT);
                    onNext();
                }else{
                    const tenDigitRegex = /^\d{10}$/;
                    const rowList = formData.dmtList.map(({ tn_number, ...rest }) => ({ telephoneNumber: tn_number, ...rest, translated_dnis: (formData.mapping_type == 2 && tenDigitRegex.test(rest.translated_dnis)) ? `1${rest.translated_dnis}` : rest.translated_dnis }));
                    const combineData = {
                        company_id : formData.cid,
                        mapping_type :formData.mapping_type,
                        import_type : 1,
                        rowList : rowList,
                        updatedDMT : [],
                        existingDMTList: []
                    }
                    setPopupOpen(true);
                    try {
                        const response = await axios.post(`${baseUrl}/digit-mapping/import`, combineData,{
                            headers: {
                                 Authorization: `Bearer ${token}`

                            }
                        });
                    
                        const allArray = response.data;
                        navigate("/digit-mapping");
                    } catch (error) {
                        console.error(error)
                    }
                    setButtonDisabled(false);
                    setPopupOpen(false)
                
                }
            } catch (error) {
                console.log(error);
                setButtonDisabled(false);
                setPopupOpen(false);
                if (error.response) {
                    setIsSuccess(false);
                    setMsg(error.response.data.msg);
                    window.scrollTo(0, 0);
                }
            }
            // onNext();
            // try {
            //     setButtonDisabled(true);
            //     setPopupOpen(true);
            //     await axios.post(`${baseUrl}/digit-mapping/add`,{formData:formData},{headers: {
            //         Authorization: `Bearer ${token}`
            //     }});
            //     setIsSuccess(true);
            //     setPopupOpen(false);
            //     navigate("/digit-mapping");
            // } catch (error) {
            //     setButtonDisabled(false);
            //     setPopupOpen(false);
            //     if (error.response) {
            //         setIsSuccess(false);
            //         setMsg(error.response.data.msg);
            //         window.scrollTo(0, 0);
            //     }
            // }
        }
    }
   
    const validationCheck = async(formData) => {

        const errors = [];
        if (!formData.cid) {
            errors.push('company is required');
        }
        if (formData.dmtList.some(item => !item.tn_number || !item.alias)) {
            errors.push('DMT type are required');
        }
        if(formData.mapping_type == 1 && formData.dmtList.some(item => !item.translation_type)){
            errors.push('Transalation type are required for all items');
        }
        // if(formData.dmtList.some((item) => item.translation_type == 2)){
           
                for(const item of formData.dmtList){
                if (item.translated_dnis == "" && !(formData.methodType == 1 && formData.mapping_type == 1 && item.translation_type == 1)) {
                    errors.push(`Translated DNIS is required`);
                } else {
                    
                    if((formData.methodType == 0 && formData.mapping_type == 1) || (item.translated_dnis && formData.methodType == 1 && formData.mapping_type == 1 && item.translation_type == 1)){
                        const response = await axios.post(`${baseUrl}/digit-mapping/check-translated`,{company_id: formData.cid, translated_dnis : item.translated_dnis},{headers: {
                            Authorization: `Bearer ${token}`
                        }});
                    
                    
                        const isEmptyOrNotFound = !response.data || !response.data[0]?.data || response.data[0].data.length === 0;
                        if(isEmptyOrNotFound === true){
                            errors.push(`No route found for Translated DNIS.`);
                        }
                    }
                   
                    if(formData.mapping_type == 2 || (formData.methodType == 1 && formData.mapping_type == 1 && item.translation_type == 2)){
                        const tenDigitRegex = /^\d{10}$/;
                        const elevenDigitRegex = /^1\d{10}$/;
                        if (tenDigitRegex.test(item.translated_dnis)) {
                            item.translated_dnis = `1${item.translated_dnis}`;  // Prepend '1'
                        }else if (elevenDigitRegex.test(item.translated_dnis)) {
                            // No change needed, number is already in the correct format
                        } else {
                            errors.push(`Please Enter NPANXXNNNN format.`);
                        }
                    }    
                }
            }
        // }
        setErrors([...new Set(errors)]);
        return errors;
    }
    const handleKeyDown = (e) => {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
          e.preventDefault();
        }
    };
    
 
    return (
        
         
        <>
                {popupOpen && (
                     <Loader /> 
                ) }
                
                    <div className="fullrow">
                        <div className="row">
                            <div className="col-6">
                                <div className="left-title">
                                    <h1>Add Digit Mapping</h1>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="addnew-button">
                                    <Link to="/view-order"><span>&larr;</span> Back</Link>
                                </div>
                            </div>
                        </div>
                        {errors.length > 0 && (
                <ul style={{ color: 'red' }}>
                    {errors.map((error, index) => (
                        <li key={index}>{error}</li>
                    ))}
                </ul>
            )}
                        <div className="row">
                            <div className="col-12">
                            <p className={`has-text-centered ${isSuccess ? 'success' : 'error'}`}>{msg}</p>
                                <div className="edit-staff-data-form add-new-staff-form">
                                    <form className="form-box" onSubmit={handleSubmit} ref={formRef}>
                                        <div className="common-white-shadow-background">
                                            <div className="innerboxwidth">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h3>Digit Mapping</h3>
                                                    </div>
                                                    <div className="col-12">
                                                        <label>Select Company</label>
                                                        {role <=2 &&
                                                        <select name="cid" id="user_role"  onChange={handleInputChange} disabled={dmtNumberList.length > 0} value={formData.cid} >
                                                            <option value="">Select Company</option>
                                                                 {company.map((comp) => (                                                                    
                                                                    <option key={comp.id} value={comp.id}>
                                                                        {comp.cname}
                                                                    </option>  
                                                            ))}
                                                        </select> }
                                                        {role > 2 && <select name="cid" id="user_role"  disabled onChange={handleInputChange} >
                                                            <option value="">Select Company</option>
                                                                 {company.map((comp) => (                                                                    
                                                                    <option key={comp.id} selected value={comp.id}>
                                                                        {comp.cname}
                                                                    </option>  
                                                            ))}
                                                        </select>}
                                                       
                                                    </div>

                                                    
                                                    <div className='col-12 dmt-type'>
                                                        <div className="settingbox radio-standard-box">
                                                            <label for="pastNumber"><input type="radio" id="pastNumber" name="methodType" value={0} checked={formData.methodType == 0} onChange={handleInputChange} />
                                                                Basic</label>          
                                                            {checkAdvanceDigitMapping && <label for="fileUpload"><input type="radio" id="fileUpload" name="methodType" value={1} checked={formData.methodType == 1} onChange={handleInputChange} />
                                                                Advance</label>}
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <label>Digit Mapping type</label>
                                                        <select name="mapping_type" id="mapping_type" value={formData.mapping_type} onChange={handleDMTTypeChange}  >
                                                            <option value="">Select Type</option>
                                                            <option value="1">Standard Digit Mapping</option>
                                                            <option value="2">Offnet Digit Mapping</option>
                                                                  
                                                            
                                                        </select> 
                                                    </div>
                                                    <div className="col-12">
                                                        <label>Description</label>
                                                        <input type="text" name="description"  onChange={handleMultipleInputChange} />                                                        
                                                        
                                                    </div>
                                                    {formData.methodType == 1 && formData.mapping_type === "1" &&
                                                    <>
                                                    
                                                     <div className="col-12">
                                                        <label>Translation type</label>
                                                        <select name="translation_type" id="translation_type" onChange={handleMultipleInputChange}  >
                                                            <option value="">Select Type</option>
                                                            <option value="1">ANI</option>
                                                            <option value="2">DNIS</option>
                                                                  
                                                            
                                                        </select> 
                                                       
                                                    </div></>}
                                                    
                                                    {(formData.mapping_type === "2" || (formData.mapping_type === "1" && (formData?.dmtList?.[0].translation_type == 1 || formData?.dmtList?.[0].translation_type == 2))) &&
                                                    <>
                                                    {formData.methodType == 1 && (formData.mapping_type === "2" || formData?.dmtList?.[0].translation_type == 2) &&
                                                    <><div className="col-md-8">
                                                        <label>Origination ANI</label>
                                                        <input type="text" name="original_ani"  placeholder="Any" onChange={handleMultipleInputChange}  />
                                                        
                                                    </div>
                                                    </>}
                                                    {formData.methodType == 1 && <div className="col-md-2">
                                                        <label>Minimum Length</label>
                                                        <input type="text" name="ani_min_length" placeholder='0'  onChange={handleMultipleInputChange}  />
                                                       
                                                    </div>}
                                                    {formData.methodType == 1 &&<div className="col-md-2">
                                                        <label>Maximum Length</label>
                                                        <input type="text" name="ani_max_length" placeholder='40'  onChange={handleMultipleInputChange}  />
                                                                                                                
                                                    </div>  }                                                  
                                                    {formData.methodType == 1 && <div className="col-sm-12">
                                                        <label>Translated ANI</label>
                                                        <input type="number" name="translated_ani" onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown} onChange={handleMultipleInputChange} />
                                                        
                                                    </div>}
                                                    {formData.methodType == 1 &&<div className="col-sm-12">
                                                        <label>ANI Action</label>
                                                        <select name="ani_action" id="ani_action"  value={formData.dmtList[0].ani_action} onChange={handleMultipleInputChange} >
                                                            <option value="0">all</option>
                                                            <option value="1">match</option>
                                                            <option value="2">passthrough</option>
                                                            <option value="3">prepend</option>
                                                            
                                                        </select> 
                                                        
                                                    </div>}
                                                    
                                                    {formData.dmtList[0].translation_type == 1 &&<>
                                                    <div className="col-md-8">
                                                        <label>Origination DNIS</label>
                                                       
                                                         <input type="text" name="original_dnis"  placeholder="Any" onChange={handleMultipleInputChange}  />
                                                        
                                                    </div>
                                                    </>}
                                                    {formData.methodType == 1 && <div className="col-md-2">
                                                        <label>Minimum Length</label>
                                                        <input type="text" name="dnis_min_length"  placeholder='0' onChange={handleMultipleInputChange} />
                                                        
                                                    </div>}
                                                    {formData.methodType == 1 &&<div className="col-md-2">
                                                        <label>Maximum Length</label>
                                                        <input type="text" name="dnis_max_length"  placeholder='40' onChange={handleMultipleInputChange}  />
                                                                                                                
                                                    </div>}
                                                    <div className="col-sm-12">
                                                        <label>Translated DNIS</label>
                                                        <input type="number" name="translated_dnis" onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown} onChange={handleMultipleInputChange} />
                                                      
                                                    </div>
                                                    
                                                    {formData.methodType == 1 &&<div className="col-sm-12">
                                                        <label>DNIS Action</label>
                                                        <select name="dnis_action" id="dnis_action" value={formData.dmtList[0].dnis_action}   onChange={handleMultipleInputChange} >
                                                            <option value="0">all</option>
                                                            <option value="1">match</option>
                                                            <option value="2">passthrough</option>
                                                            <option value="3">noLNP</option>
                                                            <option value="4">prepend</option>
                                                            
                                                        </select> 
                                                      
                                                    </div>}
                                                  </>}
                                                </div>
                                            </div>
                                        </div>
                                        
                                    <div className="col-12">
                                        <input type="submit" value="Submit" className={buttonDisabled ? 'yellow-button disabled-link' : 'yellow-button'} />
                                    </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
               
            
                
           </>
        
    )
}

const Step3 = ({ onBack, onNext, token, formData, setFormData, dmtList, existingDMTList  }) => {
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [updatedDMT, setUpdatedDMT] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [digitMappingList, setDigitMappingList] = useState([]);
    const [existingDigitMappingList, setExistingDigitMappingList] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if(dmtList.length > 0){
            setDigitMappingList(dmtList);
            setUpdatedDMT(dmtList);
            // getDigitMappingList();
        }
    }, [dmtList]);

    useEffect(() => {
        if(existingDMTList.length > 0){
            setExistingDigitMappingList(existingDMTList)
        }

    }, [existingDMTList])

    // const getDigitMappingList = async () => {
    //     setPopupOpen(true);
       
    //     const aniNumberList = formData.dmtList.filter(record => record.translation_type === "1").map(item => item.tn_number);
    //     const dnisNumberList = formData.dmtList.filter(record => (record.translation_type === "2" || formData.mapping_type == 2)).map(item => item.tn_number);
       
    //     const response = await axios.post(`${baseUrl}/get-digitmapping-tnnumber`, {aniNumberList : aniNumberList,dnisNumberList:dnisNumberList , company_id: formData.cid},{
    //         headers: {
    //             Authorization: `Bearer ${token}`
    //         }
    //     });
   
    //     const updatedArray = response.data[0].data.map(obj => ({ ...obj, ["status"]: 1 }));
    //     setDigitMappingList(response.data[0].data);
    //     // setSelectedRows(response.data[0].data);
    //     setUpdatedDMT(updatedArray);
    //     setPopupOpen(false);
    // }
    const handleSwitchSelectAll = (e) => {
        if (selectAll) {
            const updatedArray = digitMappingList.map(obj => ({ ...obj, ["status"]: 0 }));
            setSelectedRows([]);
            setUpdatedDMT(updatedArray);
        } else {
            const updatedArray = digitMappingList.map(obj => ({ ...obj, ["status"]: 1 }));
            setSelectedRows(digitMappingList); // Select all rows
            setUpdatedDMT(updatedArray);
        }
        setSelectAll(!selectAll);
    };

    const handleToggleSelect = (index) => {
        const updatedSelectedRows = selectedRows.includes(digitMappingList[index])
            ? selectedRows.filter(item => item !== digitMappingList[index]) // Deselect
            : [...selectedRows, digitMappingList[index]]; // Select

        const updateArray = digitMappingList.map((obj) => {
            const existsInUpdated = updatedSelectedRows.some(updatedObj => updatedObj.alias === obj.alias);
            if(existsInUpdated){
                obj.status = 1;
            }else{
                obj.status = 0;
            }
            return obj;
        })
        setUpdatedDMT(updateArray);
        setSelectedRows(updatedSelectedRows);

    };

    const handleNext = async(e) => {
        e.preventDefault();
        const {name} = e.target;
        let importType; 
        if(name == "import"){
            importType = 1;
        }else if(name == "remove_import"){
            importType = 2;
        }else if(name == "remove_all_import"){
            importType = 3;
        }
        const tenDigitRegex = /^\d{10}$/;
        const rowList = formData.dmtList.map(({ tn_number, ...rest }) => ({ telephoneNumber: tn_number, ...rest, translated_dnis: (formData.mapping_type == 2 && tenDigitRegex.test(rest.translated_dnis)) ? `1${rest.translated_dnis}` : rest.translated_dnis }));
        const combineData = {
            company_id : formData.cid,
            mapping_type :formData.mapping_type,
            import_type : importType,
            rowList : rowList,
            updatedDMT : updatedDMT,
            existingDMTList:existingDMTList
        }
        setPopupOpen(true);
        try {
            const response = await axios.post(`${baseUrl}/digit-mapping/import`, combineData,{
                headers: {
                     Authorization: `Bearer ${token}`
                     
                }
            });
    
            const allArray = response.data;
            navigate("/digit-mapping");
        } catch (error) {
            console.error(error)
        }
        setPopupOpen(false)
        
    }
    return(
        <>
        {popupOpen && (
             <Loader /> 
        ) }
        {/* <div className="common-white-shadow-background setting-section tableinput-section order-number-four-table">
            <div style={{ display: "flex" }}>
                <div className="contact-input order-con-title">Associated DMT List</div>
            </div>     
        </div> */}
        <div className="common-white-shadow-background setting-section tableinput-section order-number-four-table">
        <div className="row footer-row">
            <div className="footer-row-left">
                <Link className="Cancel" to={"/number-management"}>Cancel</Link>                
            </div>
            <div className="footer-row-right">
                {/* <a href="#" className="Cancel">Cancel</a> */}
                
                {(digitMappingList.length > 0 || existingDigitMappingList.length > 0) && <a href="#" name="remove_all_import" className={buttonDisabled ? 'next disabled-link newrow' : 'next newrow'} onClick={(e) => {handleNext(e)}} >Remove all & Import</a>}

                {/* {selectedRows.length > 0 && <a href="#" name="remove_import" className={buttonDisabled ? 'next disabled-link newrow' : 'next newrow'} onClick={(e) => {handleNext(e)}} >Remove & Import</a>}
                <a href="#" name="import" className={buttonDisabled ? 'next disabled-link' : 'next'} onClick={(e) => {handleNext(e)}} >Import</a> */}
            </div>
        </div>
        </div>
        {digitMappingList.length > 0 && <div className="common-white-shadow-background setting-section tableinput-section order-number-four-table">
                <div className="row">
                    <div className="order-con-title">Review Existing Associated DMT</div>  
                    
                </div>
                <table className="tablebox new-number-two-setup">
                    <thead>
                        <tr>
                            {/* <th><input type="checkbox" className="newrow checkboxAll"  onChange={handleSwitchSelectAll} checked={selectAll} /></th> */}
                            <th>Alias</th>
                            <th>Origination ANI</th>
                            <th>Match Length</th>
                            <th>Origination DNIS</th>
                            <th>Match Length</th>
                            <th>Translated ANI</th>
                            <th>Translated DNIS</th>
                            <th>ANI Action</th>
                            <th>DNIS Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {digitMappingList.map((dmt, index) => (
                            <tr key={index}>
                                {/* <td>{selectedRows[0].alias}</td> */}
                            {/* <td><input type="checkbox"  checked={selectedRows.includes(dmt)} onChange={() => handleToggleSelect(index)} /></td> */}
                            <td>{dmt.alias}</td>
                            <td>{dmt.origAni}</td>
                            <td>{dmt.origAniMinLength} - {dmt.origAniMaxLength}</td>
                            <td>{dmt.origDnis}</td>
                            <td>{dmt.origDnisMinLength} - {dmt.origDnisMaxLength}</td>
                            <td>{dmt.transAni}</td>
                            <td>{dmt.transDnis}</td>
                            <td>{dmt.aniAction}</td>
                            <td>{dmt.dnisAction}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>}
        {digitMappingList.length === 0 && <div className="common-white-shadow-background setting-section tableinput-section order-number-four-table">
            <div className="row">
                <div className="order-con-title">Review Existing Associated DMT</div>  
                
            </div>
            
            <div>No Record Found</div>
        </div>}
        {existingDigitMappingList.length > 0 && <div className="common-white-shadow-background setting-section tableinput-section order-number-four-table">
                <div className="row">
                    <div className="order-con-title">Below are the existing DMTs in the portal</div>  
                    
                </div>
                <table className="tablebox new-number-two-setup">
                    <thead>
                        <tr>
                            {/* <th><input type="checkbox" className="newrow checkboxAll"  onChange={handleSwitchSelectAll} checked={selectAll} /></th> */}
                            <th>Alias</th>
                            <th>Origination ANI</th>
                            <th>Match Length</th>
                            <th>Origination DNIS</th>
                            <th>Match Length</th>
                            <th>Translated ANI</th>
                            <th>Translated DNIS</th>
                            <th>ANI Action</th>
                            <th>DNIS Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {existingDigitMappingList.map((dmt, index) => (
                            <tr key={index}>
                                {/* <td>{selectedRows[0].alias}</td> */}
                            {/* <td><input type="checkbox"  checked={selectedRows.includes(dmt)} onChange={() => handleToggleSelect(index)} /></td> */}
                            <td>{dmt.alias}</td>
                            <td>{dmt.original_ani}</td>
                            <td>{dmt.ani_min_length} - {dmt.ani_max_length}</td>
                            <td>{dmt.original_dnis}</td>
                            <td>{dmt.dnis_min_length} - {dmt.dnis_max_length}</td>
                            <td>{dmt.translated_ani}</td>
                            <td>{dmt.translated_dnis}</td>
                            <td>{dmt.ani_action == 0 ? "All" : (dmt.ani_action == 2 ? "Passthrough" : (dmt.ani_action == 3 ? "Prepend" : "Match"))}</td>
                            <td>{dmt.dnis_action == 0 ? "All" : (dmt.dnis_action == 2 ? "Passthrough" : (dmt.dnis_action == 3 ? "noLNP" : (dmt.dnis_action == 4 ? "Prepend" : "Match")))}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>}
        
        {existingDigitMappingList.length === 0 && <div className="common-white-shadow-background setting-section tableinput-section order-number-four-table">
            <div className="row">
                <div className="order-con-title">Below are the existing DMTs in the portal</div>  
                
            </div>
            
            <div>No Record Found</div>
        </div>}
        
        </>
    );
}

const AddMultipleDigitMapping = () => {
    const location = useLocation();
    const { compid, successNumberList, checkAdvanceDigitMapping } = location.state || {};
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [role, setRole] = useState('');
    const [access, setAccess] = useState([]);
    const [numberManagementAccess, setNumberManagementAccess] = useState(true);
    const [dmtList, setDMTList] = useState([]);
    const [dmtNumberList, setDmtNumberList] = useState([]);
    const [existingDMTList, setExistingDMTList] = useState([]);
    const [cid, setCid] = useState('');
    const [formData, setFormData] = useState({ 
        cid: compid ?? '',
        methodType: 0,
        mapping_type: "0",
        dmtList : []     
    });
    
    const [step, setStep] = useState(1);
   
    
    const navigate = useNavigate();

   
 
    useEffect(() => {
        refreshToken();
        getUserAccess();
    }, []);

    useEffect(() => {
        if(access.length > 0 && token){
            checkAccess(access, token);
        }
    }, [access, token, numberManagementAccess]);
    
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded.exp);
            setCid(decoded.cid);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }
    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            setNumberManagementAccess(response.data.number_management_switch == 0 ? false : true);
            
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }

    const hasPermission = (permission) => {
        return access.some((item) => item.access === permission);
    };

    const checkAccess = async (access) => {
        if(!hasPermission('ordernewnumber') || !numberManagementAccess){
            navigate('/dashboard');
        }
    }
    

    
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setCid(decoded.cid);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const handleNext = () => setStep(step + 1);
    const handleBack = () => setStep(step - 1);
 
    const renderStep = () => {
        switch (step) {
          case 1:
            return <Step1 onNext={handleNext} cid={cid} successNumberList={successNumberList} setDmtNumberList={setDmtNumberList} role={role} token={token} formData={formData} setFormData={setFormData} setDMTList = {setDMTList} />;
          case 2:
            return <Step2 onNext={handleNext} cid={cid} checkAdvanceDigitMapping={checkAdvanceDigitMapping} dmtNumberList={dmtNumberList} role={role} token={token} formData={formData} setFormData={setFormData} setDMTList = {setDMTList} setExistingDMTList={setExistingDMTList} />;
          case 3:
            return <Step3 onBack={handleBack} onNext={handleNext} token={token} formData={formData} setFormData={setFormData} dmtList = {dmtList} existingDMTList={existingDMTList} />;
          default:
            return null;
        }
      };
 
    return (
        <div className="panelbox">
           <Navbar  token={token} access = {access}/>
            <div className="right-panel">
            <Header name={name} />
                <div className="content-page admin-new-addstaff-page">
                    {renderStep()}
                </div>
            </div>
        </div>
    )
}
 
export default AddMultipleDigitMapping