
import React, { useEffect, useState } from 'react'
import Loader from "../../Loader";
 
const OrderSummaryDetail = (props) => {

    const [summary, setSummary] = useState(props.summary);
    const [orderType, setOrderType] = useState(props.ordertype?.order_type);
    const [totalSuccessNumberCount, setTotalSuccessNumberCount] = useState(0);
    const [totalFailedNumberCount, setTotalFailedNumberCount] = useState(0);
    const [popupOpen, setPopupOpen] = useState(false);    
    useEffect(() => {
        console.log(props.ordertype?.disconnect_skip_carrier);
        setSummary(props.summary.map(filterObject));
        setOrderType(props.ordertype?.order_type);
        let total_success = 0;
        let total_failed = 0;
        if(props.allNumberList.length > 0){
            
                props.allNumberList.map((item) => {
                    if(props.ordertype?.order_type == 0 && (item.number_status == 1 && item.sansay_status == 1 && item.rome_status == 1 || (item.cname_flag == 1 && item.cname_status == 1))){
                        total_success += 1;
                    }else if(props.ordertype?.order_type == 1 && item.sansay_status == 1){
                        total_success += 1;
                    }else if(props.ordertype?.order_type == 2 && props.ordertype?.disconnect_skip_carrier == 1 && (item.sansay_status == 1 && item.rome_status == 1)){
                        total_success += 1;
                    }else if(props.ordertype?.order_type == 2 && props.ordertype?.disconnect_skip_carrier == 0  && (item.number_type == 1  || item.number_type == 0) && (item.number_status == 1 && item.sansay_status == 1 && item.rome_status == 1)){
                        total_success += 1;
                    }else if(props.ordertype?.order_type == 2 && props.ordertype?.disconnect_skip_carrier == 0  && (item.number_type != 1  && item.number_type != 0) && (item.sansay_status == 1 && item.rome_status == 1)){
                        total_success += 1;
                    }else if(props.ordertype?.order_type == 3 &&(item.cname_flag == 1 && item.cname_status == 1)){
                        total_success += 1;
                    }else if((props.ordertype?.order_type == 4 || props.ordertype?.order_type == 5) && (item.sansay_status == 1)){
                        total_success += 1;
                    }

                    if(props.ordertype?.order_type == 0 && (item.number_status == 2 || item.sansay_status == 2 || item.rome_status == 2 || (item.cname_flag == 1 && item.cname_status == 2))){
                        total_failed += 1;
                    }else if(props.ordertype?.order_type == 1 && item.sansay_status == 2){
                        total_failed += 1;
                    }else if(props.ordertype?.order_type == 2 && props.ordertype?.disconnect_skip_carrier == 1 &&(item.sansay_status == 2 || item.rome_status == 2)){
                        total_failed += 1;
                    }else if(props.ordertype?.order_type == 2 && props.ordertype?.disconnect_skip_carrier == 0 &&(item.number_status == 2 || item.sansay_status == 2 || item.rome_status == 2)){
                        total_failed += 1;
                    }else if(props.ordertype?.order_type == 3 &&(item.cname_flag == 1 && item.cname_status == 2)){
                        total_failed += 1;
                    }else if((props.ordertype?.order_type == 4 || props.ordertype?.order_type == 5) && (item.sansay_status == 2)){
                        total_failed += 1;
                    }
                })
            
        }
        setTotalFailedNumberCount(total_failed);
        setTotalSuccessNumberCount(total_success);
    }, [props]); 
    const filterObject = (obj) => {
        return Object.keys(obj).reduce((acc, key) => {
          if (obj[key] > 0) {
            acc[key] = obj[key];
          }
          return acc;
        }, {});
      };
      
    console.log(summary);
    console.log(orderType);
    
   
    return (
        <>
        {popupOpen && (
            <Loader /> 
        )}
        <div>
            <div className='row order-detail'>
            <div className="col-3"><strong>Total Number Request: </strong> {summary[0]?.total_number}</div>
            <div className="col-3"><strong>Total Number Success: </strong> 
            {totalSuccessNumberCount}
            {/* {Math.max(
    Number(summary[0]?.number_success ?? 0),
    Number(summary[0]?.sansay_success ?? 0),
    Number(summary[0]?.rome_success ?? 0),
    Number(summary[0]?.cname_success ?? 0),
    Number(summary[0]?.e911_success ?? 0)
)} */}
</div>
            <div className="col-3"><strong>Total Number Failed: </strong> 
            {totalFailedNumberCount}
            {/* { Math.max(
    Number(summary[0]?.number_failed ?? 0),
    Number(summary[0]?.sansay_failed ?? 0),
    Number(summary[0]?.rome_failed ?? 0),
    Number(summary[0]?.cname_failed ?? 0),
    Number(summary[0]?.e911_failed ?? 0)
)} */}
</div>
            </div>
            {props.role <=2 && <div className="row order-detail">


                    {(orderType == 0 || orderType == 2)  && (summary[0]?.number_success || summary[0]?.number_failed || summary[0]?.number_progress) && <div className="col-3">
                        {summary[0]?.number_success && <div><strong>Carrier Success: </strong> {summary[0]?.number_success}</div>}
                        {summary[0]?.number_failed &&  <div><strong>Carrier Failed: </strong> {summary[0]?.number_failed}</div>}
                        {summary[0]?.number_progress &&  <div><strong>Carrier InProgress: </strong> {summary[0]?.number_progress}</div>}
                    </div>}
                    {(summary[0]?.sansay_success || summary[0]?.sansay_failed) && <div className="col-3">
                        {summary[0]?.sansay_success && <div><strong>Sansay Success: </strong> {summary[0]?.sansay_success}</div>}
                        {summary[0]?.sansay_failed && <div><strong>Sansay Failed: </strong> {summary[0]?.sansay_failed}</div>}
                    </div>}
                    {(orderType != 4 && orderType != 5)  && (summary[0]?.rome_success || summary[0]?.rome_failed) && <div className="col-3">
                        {summary[0]?.rome_success && <div><strong>Rome Success: </strong> {summary[0]?.rome_success}</div>}
                        {summary[0]?.rome_failed && <div><strong>Rome Failed: </strong> {summary[0]?.rome_failed}</div>}
                    </div>}
                    
                    {((orderType == 0 && props.ordertype?.tn_details.filter((item) => item.cname_flag == 1).length > 0) || orderType == 3) && (summary[0]?.cname_inprogress || summary[0]?.cname_success || summary[0]?.cname_failed)  && <div className="col-3">
                        {summary[0]?.cname_success && <div><strong>CNAM Success: </strong> {summary[0]?.cname_success}</div>}
                        {summary[0]?.cname_failed && <div><strong>CNAM Failed: </strong> {summary[0]?.cname_failed}</div>}
                        {summary[0]?.cname_inprogress && <div><strong>CNAM Inprogress: </strong> {summary[0]?.cname_inprogress}</div>}
                    </div>}
                    {(orderType == 3) && (summary[0]?.e911_success || summary[0]?.e911_failed) && <div className="col-3">
                        {summary[0]?.e911_success && <div><strong>E911 Success: </strong> {summary[0]?.e911_success}</div>}
                        {summary[0]?.e911_failed && <div><strong>E911 Failed: </strong> {summary[0]?.e911_failed}</div>}
                    </div>}
                    
                    
                    
            </div>}
        </div>
       
        </>
    )
}
 
export default OrderSummaryDetail
