/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../../Navbar";
import Header from "../../Header";
import baseUrl from "../../BaseUrl";
import Loader from "../../Loader";
import { saveAs } from 'file-saver';
import { useNavigate, Link } from 'react-router-dom';
import PasswordResetModal from "../comman/PasswordResetModal";
import Pagination from "react-js-pagination";
// require("bootstrap/less/bootstrap.less");
 
const List = () => {
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [role, setRole] = useState('');
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [company, setCompany] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(0);
    const [selectedUserType, setSelectedUserType] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [access, setAccess] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
        getUserAccess();
        getUserCompany();
        // getUsers();
    }, []);
    useEffect(() => {
        getUsers(page,selectedCompany, selectedUserType, searchValue);
    }, [page]);
    useEffect(() => {
        if(access.length > 0 && token){
            checkAccess(access, token);
        }
        
    }, [access, token]);
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded);
            setRole(decoded.role);
            setName(decoded.name);
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }

    const handlePageChange  = (pageNumber) => {
        // console.log(`active page is ${pageNumber}`);
        setPage(pageNumber);
    }
    const handleComapnyChange = (e) => {
        setSelectedCompany(e.target.value);
        // setPage(1);
        // console.log(e.target.value);
        // getUsers(page,e.target.value, selectedUserType); // Reset page to 1 when filter changes
    };

    const handleUserTypeChange = (e) => {
        setSelectedUserType(e.target.value);
        // setPage(1);
        // console.log(e.target.value);
        // getUsers(page,selectedCompany, e.target.value, ''); // Reset page to 1 when filter changes
    };
    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    };
    const handleSearchSubmit = () => {
        setPage(1);
        getUsers(1, selectedCompany, selectedUserType, searchValue);
    };

    const clearFilter = () => {
        setSelectedUserType(0);
        setSelectedCompany(0);
        setSearchValue('');
        setPage(1);
        getUsers(page,0, 0, '');
    }

    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }
    const hasPermission = (permission) => {
        return access.some((item) => item.access === permission);
    };

    const checkAccess = async (access) => {
        if((role === 2 && !hasPermission('viewuser')) || role === 5){
            navigate('/dashboard');
        }
    }
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
 
    const getUsers = async (page, cid,usertype, search) => {
        // console.log(page);
        setPopupOpen(true);
        const response = await axiosJWT.get(`${baseUrl}/users?page=${page}&limit=${20}&company=${cid}&userType=${usertype}&search=${search}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);
        console.log(response.data);
        setUsers(response.data.data);
        setTotalItem(response.data.totalItem);
    }

    const getUserCompany = async () => {
        
        const response = await axiosJWT.get(`${baseUrl}/active-user-company`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        // console.log(response.data);
        setCompany(response.data);
    }

    const deleteUser = async (id) => {
        const shouldDelete = window.confirm("Are you sure you want to disable this user?");
    if (shouldDelete) {
     
    
        try {
            
            await axios.delete(`${baseUrl}/users/${id}`,{headers: {
                Authorization: `Bearer ${token}`
            }});
            window.location.reload();
        } catch (error) {
            console.log(error);
            // if (error.response) {
            //     setMsg(error.response.data.msg);
            // }
        }
    }
        
    };

    const activateUser = async (id) => {
        const shouldDelete = window.confirm("Are you sure you want to re-activate this user?");
    if (shouldDelete) {
     
    
        try {
            
            await axios.get(`${baseUrl}/users/${id}`,{headers: {
                Authorization: `Bearer ${token}`
            }});
            window.location.reload();
        } catch (error) {
            console.log(error);
            // if (error.response) {
            //     setMsg(error.response.data.msg);
            // }
        }
    }
        
    };

    const handleSuperAdminLogin = async (userEmail)=>{
        try {
            await axios.post(`${baseUrl}/super-admin-login`,{email: userEmail})
            console.log('Logout from super admin')
            navigate('/dashboard')
            console.log('Logged in a user')
        } catch (error) {
            console.log(error);
        }
    }

    const handlePasswordReset = async(id) => {
        setIsModalOpen(true);
        setSelectedUserId(id);

    }

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedUserId(null); // Clear the selected user
    };

    const handleExportCSV = async(e) => {
        e.preventDefault();
        setPopupOpen(true);
        
        const response = await axiosJWT.get(`${baseUrl}/users?page=${page}&limit=${20}&company=${selectedCompany}&userType=${selectedUserType}&search=${searchValue}&type=${2}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);
        const headerRow = ["First Name", "Last Name", "Company", "Email",  "User Type", "Status"];
        const list = response?.data?.data ?? [];
        const csv = [headerRow].concat(
            list.map(row => {
                
            const user_type = row.role === 1 ? 'Super Admin' : row.role === 2 ? 'BBD Support User' : row.role === 3 ? 'Reseller admin user' : row.role === 4 ? 'Customer admin user' :  row.role === 5 ? 'Customer user' : '-';
            const status = row.is_active === 1 ? "Active" : "Not Active";
            const result = [row.fname, row.lname, row.cname, row.email,  user_type, status];
                 
            return result.join(',')
        })).join('\n');
        // Create a Blob containing the CSV data
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      
        // Trigger the file download using file-saver
        saveAs(blob, 'user_list.csv');
      };
 
    return (
        <div className="panelbox">
           <Navbar token={token} access={access}/>
            <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
            <div className="content-page admin-attendance-page">
                <div className="row">
                    <div className="col-6">
                        <div className="left-title">
                            <h1>User Listing</h1>
                        </div>
                    </div>
                    <div className="col-6 top-right-buttons">
                        {role < 2 && <div className="export-btn newrow"><a className="newrow" onClick={handleExportCSV} href="#">Export</a></div>} 
                        {hasPermission('createuser') && <div className="addnew-button">
                            <Link to="/user-add"><span>+</span> Add User</Link>
                        </div>}
                    </div>
                </div>
                <div className="fullrow">
                    <div className="row admin-staff-list-toppart">
                        <div className="col-md-8">
                            <div className="boxinput">
                            
                            <input className="search-leave satff-listing-page-search" type="text" name="search" placeholder="Search" value={searchValue} onChange={handleSearchChange} />
                      
                                <select className="company-name form-control" name="company" value={selectedCompany} onChange={handleComapnyChange}>
                                    <option value={0} >Company</option>
                                    {company.map((comp, index) => (
                                                              <option key={index} value={comp.id}>
                                                                {comp.cname}
                                                              </option>
                                                            ))}
                                </select>
                                <select className="form-control" name="usertype" value={selectedUserType} onChange={handleUserTypeChange}>
                                    <option value={0} >User Type</option>                                    
                                    <option value="2">BBD Support User</option>
                                    <option value="3">Reseller admin user</option>
                                    <option value="4">Customer admin user</option>
                                    <option value="5">Customer user</option>
                                </select>
                                {/* <a href="#">Search</a> */}
                                <button  className="search-btn" onClick={handleSearchSubmit}>Search</button>
                               { (selectedCompany || selectedUserType || searchValue) && (<a className='search-btn clear-btn' onClick={clearFilter} >Clear</a>)}
                            </div>
                        </div>
                        
                    </div>
                    
                    <div className="row">
                        <div className="col-12">
                            <div className="fullwidth-table track-table-body staff-listing-admin-table-body">
                                <table>
                                    <thead>
                                    <tr>
                                        
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Company</th>
                                        <th>E-mails</th>
                                        <th>User Type</th>
                                        <th>Active</th>
                                        <th></th>  
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {users.map((user, index) => (
                                        <tr key={user.id}>
                                            
                                            <td>{user.fname}</td>
                                            <td>{user.lname}</td>
                                            <td>{user.cname}</td>
                                            <td>{user.email}</td>
                                            <td>{user.role === 1 ? 'Super Admin' : user.role === 2 ? 'BBD Support User' : user.role === 3 ? 'Reseller admin user' : user.role === 4 ? 'Customer admin user' :  user.role === 5 ? 'Customer user' : '-'}</td>
                                            {/* <td><a href="#" className="false">False</a></td> */}
                                            <td>{user.is_active === 1 ? <a href="#" className="true">True</a> : <a href="#" className="false">False</a> }</td>
                                            
                                            <td>
                                                <ul>
                                                    {role <= 2 && <li><a className="login-user" onClick={()=>{handleSuperAdminLogin(user.email)}}>Login</a></li>}
                                                    {role <= 2 && <li><a  onClick={() => handlePasswordReset(user.id)}><img src={`${process.env.PUBLIC_URL}/images/lock.png`} alt="Reset" title="Use this option to reset password for user"/></a></li>}
                                                    {hasPermission('edituser') && <li><Link to={`/user/edit/${user.id}`}><img src={`${process.env.PUBLIC_URL}/images/bx-edit.png`} alt="Edit" title="Use this option to edit user" /></Link></li>}
                                                    {(user.is_active === 1 && hasPermission('deleteuser')) && <li><a  onClick={() => deleteUser(user.id)}><img src={`${process.env.PUBLIC_URL}/images/bx-x-circle.png`} alt="Delete" title="Use this option to disable user"/></a></li>  } 
                                                    {(user.is_active === 0 && hasPermission('createuser')) && <li><a  onClick={() => activateUser(user.id)}><img src={`${process.env.PUBLIC_URL}/images/success.png`} alt="Active" title="Use this option to enable user" /></a></li>  }                                                   
                                                </ul>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                {totalItem > 20 && (<Pagination
                                  activePage={page}
                                  itemsCountPerPage={20}
                                  totalItemsCount={totalItem}
                                  pageRangeDisplayed={5}
                                  onChange={handlePageChange.bind(this)}
                                />)}
                            </div>
                            <PasswordResetModal
                                userId={selectedUserId}
                                isOpen={isModalOpen}
                                onClose={handleCloseModal}
                            />
                        </div>
                    </div>
                </div>
            </div>
                {/* <div className="content-page">
                    <div className="row">
                        <div className="col-6">
                            <div className="left-title">
                                <h1>User Lisiting</h1>
                            </div>
                        </div>
                        <div className="col-6">

                        </div>
                    </div>
                    <table className="table is-striped is-fullwidth">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user, index) => (
                        <tr key={user.id}>
                            <td>{index + 1}</td>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                        </tr>
                    ))}
 
                </tbody>
            </table>
                    
                </div> */}
            </div>
        </div>
    )
}
 
export default List