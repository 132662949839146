/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import Header from "../Header";
import baseUrl from "../BaseUrl";
import Pagination from "react-js-pagination";
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { saveAs } from 'file-saver';
import formatDateTimeToPST from "../PSTDateConvert";
import Loader from "../Loader";
 
const InventoryList = () => {
    const location = useLocation();
    const abx = location.state;
    // console.log(abx);
    const [name, setName] = useState('');
    const [timeZone, seTimeZone] = useState('');
    const [cid, seCid] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [inventory, setInventory] = useState([]);
    const [error, setError] = useState(null);
    const [role, setRole] = useState('');
    const [access, setAccess] = useState([]);
    const [numberManagementAccess, setNumberManagementAccess] = useState(true);
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [searchValue, setSearchValue] = useState(abx != null ? abx.searchValue : "");
    const [selectedCompany, setSelectedCompany] = useState(abx != null ? abx.selectedCompany : 0);
    const [selectedCarrier, setSelectedCarrier] = useState(abx != null ? abx.selectedCarrier : "");
    const [selectedEnrmsType, setSelectedEnrmsType] = useState(abx != null ? abx.selectedEnrmsType : "");
    const [selectedType, setSelectedType] = useState(abx != null ? abx.selectedType : "");
    const [status, setStatus] = useState(abx != null ? abx.status : "0");
    const [selectedRoute, setSelectedRoute] = useState(abx != null ? abx.selectedRoute : "");
    const [company, setCompany] = useState([]);
    const [routeList, setRouteList] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [isFilter, setIsFilter] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [inventoryAccess, setInventoryAccess] = useState(false);
    const [userInventoryAccess, setUserInventoryAccess] = useState(true);
    const [userMultipleInventoryAccess, setUserMultipleInventoryAccess] = useState(false);
    const [mutipleInventoryAccess, setMutipleInventoryAccess] = useState(false);
    const [isMappingAccessFetched, setIsMappingAccessFetched] = useState(false);
    const [ciaAccess,setCiaAccess] = useState(false)
    const [carriers, setCarriers] = useState([]);
    
    
    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
        getUserAccess();
        getUserCompany();
        getCarrierList();
    }, []);
    useEffect(() => {
        if(cid){
            getComapnyRoute();
            getCustomerDigitMappingAccess();
        }
    }, [cid, selectedCompany]);
    useEffect(() => {
        if(access.length > 0 && token && isMappingAccessFetched){
            checkAccess(access, token);
        }
    }, [access, token, isMappingAccessFetched, numberManagementAccess]);
    useEffect(() => {
        if(role && carriers.length > 0){
            getOrder(page,selectedCompany, searchValue, status, selectedRoute, selectedCarrier, selectedType, selectedEnrmsType);
        }
    }, [page, role, carriers]);
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded);
            setName(decoded.name);
            seTimeZone(decoded.timezone);
            setRole(decoded.role)
            setExpire(decoded.exp);
            seCid(decoded.cid);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }
    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            setNumberManagementAccess(response.data.number_management_switch == 0 ? false : true);
            setUserInventoryAccess(response.data.user_inventory_switch == 0 ? false : true);
            setUserMultipleInventoryAccess(response.data.user_multiple_inventory_switch == 0 ? false : true);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }
    const getUserCompany = async () => {
        
        const response = await axiosJWT.get(`${baseUrl}/active-user-company`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        const company = response.data.filter(item => item.id !== 1);
        // company.shift();
        // console.log(response.data);
        setCompany(company);
    }

    const getComapnyRoute = async () => {
        let comapny_id;
        if(cid === 1){
            comapny_id = selectedCompany;
        }else{
            comapny_id = cid;
        }
        // console.log(selectedCompany);
        
        const response = await axiosJWT.get(`${baseUrl}/route-list/${comapny_id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        const route = response.data;
        // company.shift();
        // console.log(response.data);
        setRouteList(route.data);
    }

    const handlePageChange  = (pageNumber) => {
        // console.log(`active page is ${pageNumber}`);
        setPage(pageNumber);
    }
    const getOrder = async (page, cid, search, status, route, carrier, type, enrmsType) => {
        // console.log(page);
        setPopupOpen(true);
        const response = await axiosJWT.get(`${baseUrl}/inventory?page=${page}&limit=${20}&company=${cid}&search=${search}&status=${status}&route=${route}&carrier=${carrier}&type=${type}&enrmsType=${enrmsType}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);
        if(role <= 2 && cid && (status === "0" || status === 0 || status == 2)){
            setIsFilter(true);
        }else if(role > 2 && (status === "0" || status === 0)){
            setIsFilter(true);
        }else{
            setIsFilter(false);
        }

        const result = response.data.data;
        const updatedResult = result.map((item) => {
            // Determine the carrier_name based on number_type and carriers list
            const carrier_name = item.number_type == 1
                ? "Bandwidth"
                : item.number_type == 0
                ? "Inteliquent"
                : carriers.filter((carrier) => !["Inteliquent", "Bandwidth"].includes(carrier.name))
                          .find((carrier) => carrier.carrier_index == item.number_type)?.name || "-";
            return {
                ...item, // Spread the original properties of the item
                carrier_name, // Add the carrier_name property
            };
        });

        setInventory(updatedResult);
        setTotalItem(response.data.totalItem);
    }

    const getCarrierList = async () => {
        setPopupOpen(true);
        const response = await axiosJWT.get(`${baseUrl}/carrier-settings?page=${page}&limit=${2000}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);
        setCarriers(response.data.data);
    }
    const hasPermission = (permission) => {
        return access.some((item) => item.access === permission);
    };

    const checkAccess = async (access) => {
        if(role == 2 && !hasPermission('viewinventory')){
            navigate('/dashboard');
        }else if(role >= 3 && ((!inventoryAccess) || (inventoryAccess && !userInventoryAccess))){
            navigate('/dashboard');
        }  
    }
    const handleComapnyChange = (e) => {
        setSelectedCompany(e.target.value);
        // setPage(1);
        // console.log(e.target.value);
        // getUsers(page,e.target.value, status); // Reset page to 1 when filter changes
    };

    const handleUserTypeChange = (e) => {
        setStatus(e.target.value);
        // setPage(1);
        // console.log(e.target.value);
        // getUsers(page,selectedCompany, e.target.value, ''); // Reset page to 1 when filter changes
    };

    const handleTypeChange = (e) => {
        setSelectedType(e.target.value);
        // setPage(1);
        // console.log(e.target.value);
        // getUsers(page,selectedCompany, e.target.value, ''); // Reset page to 1 when filter changes
    };

    const handleCarrierChange = (e) => {
        setSelectedCarrier(e.target.value);
        // setPage(1);
        // console.log(e.target.value);
        // getUsers(page,selectedCompany, e.target.value, ''); // Reset page to 1 when filter changes
    };

    const handleEnrmsTypeChange = (e) => {
        setSelectedEnrmsType(e.target.value);
        // setPage(1);
        // console.log(e.target.value);
        // getUsers(page,selectedCompany, e.target.value, ''); // Reset page to 1 when filter changes
    };

    const handleRouteOptionChange = (e) => {
        setSelectedRoute(e.target.value);
       
    };

    const clearFilter = () => {
        setStatus("");
        setSelectedCompany(0);
        setSelectedCarrier("");
        setSelectedType("");
        setSelectedRoute('');
        setSearchValue('');
        setSelectedEnrmsType("");
        setPage(1);
        setSelectedItems([]);
        getOrder(1,0,'', '', "", "", "", "");
        setIsFilter(false);
    }

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    };
    const handleSearchSubmit = () => {
        setPage(1);
        setSelectedItems([]);
        getOrder(1,selectedCompany,searchValue, status, selectedRoute, selectedCarrier, selectedType, selectedEnrmsType);
        // getUsers(1, selectedCompany, status, searchValue);
    };

    const handleExportCSV = async(e) => {
        e.preventDefault();
        // console.log(selectedCompany);
        // console.log(searchValue);
        // console.log(status);
        // console.log(selectedRoute);
        setPopupOpen(true);
        const response = await axiosJWT.get(`${baseUrl}/number-trunk-detail?page=${page}&limit=${20}&company=${selectedCompany}&search=${searchValue}&status=${status}&route=${selectedRoute}&type=${selectedType}&carrier=${selectedCarrier}&enrms=${selectedEnrmsType}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);
        console.log(response.data.getQueryResult);
        const list = response.data.getQueryResult;
        let headerRow = [];
        if(role <= 2){
            headerRow = ["Tel Number","Company_name","Route Option","CNAM","Status","ENRMS Type","Created by", `Created at (${timeZone})`, "Carrier","Call Pass Enable Date","Call Pass Disable Date","Note", "Policy","tid1","percentage1","continuous_route1","tid2","percentage2","continuous_route2","tid3","percentage3","continuous_route3","tid4","percentage4","continuous_route4","tid5","percentage5","continuous_route5","tid6","percentage6","continuous_route6","tid7","percentage7","continuous_route7"];
        }else{
            headerRow = ["Tel Number","Company_name","Route Option","CNAM","Status","Created by", `Created at (${timeZone})`];
        }
        

        // Create a CSV string from the mapped data array
    //     console.log(inventory);
        const csv = [headerRow].concat(
       list.map(row => {
            // Replace status values with "active" or "inactive"
            const status = row.status == 0 ? "Active" : (row.status == 1 ? "Cancelled" : "Pending");
            const enrms_type = row.cas_type == 1 ? "Enable" : "Disable";
            const policy = row.policy == 1 ? "top_down" : (row.policy == 2 ? "percentage" : (row.policy == 3 ? "round_robin":"none"));
            const created_by = row.fname + " "+row.lname;
            const create_at = formatDateTimeToPST(row.created_at, timeZone,role);
            const callPassEnableDate = formatDateTimeToPST(row.call_pass_enable_date, timeZone, role);
            const callPassDisableDate = formatDateTimeToPST(row.call_pass_disable_date, timeZone, role);
            const con1 = row.continuous_route1 == "" ? "" : (row.continuous_route1 == 0 ? 'no' : "yes");
            const con2 = row.continuous_route2 == "" ? "" : (row.continuous_route2 == 0 ? 'no' : "yes");
            const con3 = row.continuous_route3 == "" ? "" : (row.continuous_route3 == 0 ? 'no' : "yes");
            const con4 = row.continuous_route4 == "" ? "" : (row.continuous_route4 == 0 ? 'no' : "yes");
            const con5 = row.continuous_route5 == "" ? "" : (row.continuous_route5 == 0 ? 'no' : "yes");
            const con6 = row.continuous_route6 == "" ? "" : (row.continuous_route6 == 0 ? 'no' : "yes");
            const con7 = row.continuous_route7 == "" ? "" : (row.continuous_route7 == 0 ? 'no' : "yes");
            // Create an array of values, including the modified 
            const carrier_name = row.number_type == 1
                ? "Bandwidth"
                : row.number_type == 0
                ? "Inteliquent"
                : carriers.filter((carrier) => !["Inteliquent", "Bandwidth"].includes(carrier.name))
                          .find((carrier) => carrier.carrier_index == row.number_type)?.name || "-";
            let result = [];
            if(role <= 2){
                result = [row.tn_number, row.cname,escapeCsvDateValue(row.customer_name), escapeCsvDateValue(row.cname_label), status,enrms_type,created_by,create_at,carrier_name,callPassEnableDate,callPassDisableDate, escapeCsvDateValue(row.note),policy,row.customer_name1,row.percentage_1,con1,row.customer_name2,row.percentage_2,con2,row.customer_name3,row.percentage_3,con3,row.customer_name4,row.percentage_4,con4,row.customer_name5,row.percentage_5,con5,row.customer_name6,row.percentage_6,con6,row.customer_name7,row.percentage_7,con7 ];
            }else{
                result = [row.tn_number, row.cname,escapeCsvDateValue(row.customer_name), escapeCsvDateValue(row.cname_label), status,created_by,create_at];
            }
            return result.join(',')
          })).join('\n');
          
      
        // Create a Blob containing the CSV data
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      
        // Trigger the file download using file-saver
        saveAs(blob, 'inventory.csv');
      };

    const escapeCsvDateValue = (value) => {
        if (typeof value === 'string') {
            // Escape existing double quotes by doubling them
            value = value.replace(/"/g, '""');
            // Wrap the value in double quotes if it contains commas or quotes
            if (value.includes(',') || value.includes('"')) {
                return `"${value}"`;
            }
        }
        return value;
    };

 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setName(decoded.name);
            seTimeZone(decoded.timezone);
            setRole(decoded.role)
            setExpire(decoded.exp);
            seCid(decoded.cid);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const handleNavigation = (e, id, number_type) => {
        e.preventDefault();
        const carrier_name = [0,1].includes(number_type);
        const allArray = {
            selectedCompany:selectedCompany,status: status, searchValue:searchValue, selectedRoute: selectedRoute, selectedCarrier:selectedCarrier,selectedType:selectedType,carrier_name:carrier_name, selectedEnrmsType:selectedEnrmsType
        }
        navigate(`/view-trunk-detail/${btoa(id)}`, { state: allArray });
    };

    const handleCheckboxChange = (item) => {
        const isChecked = selectedItems.some(tnObj => tnObj.tn === item.tn_number);
        if (isChecked) {
            setSelectedItems(prevSelected => prevSelected.filter(tnObj => tnObj.tn !== item.tn_number));
        } else {
            setSelectedItems(prevSelected => [...prevSelected, { tn: item.tn_number }]);
        }
    
    };

    

    const handleSelectAll = () => {   
  
        if (selectAll) {
            setSelectedItems([]);
        } else {
            setSelectedItems(inventory.map(tnObj => ({ tn: tnObj.tn_number })));
        }
        
        setSelectAll(!selectAll);            
    };

    const handleRemoveNumber = async(e) => {
        e.preventDefault();
        // const userConfirmed = window.confirm(`Are you sure you want to remove selected ${selectedItems.length} numbers from the inventory`);
        const userConfirmed = window.confirm("Only use this option to remove a number from the portal.  Doing this will not affect the Sansays or carriers.  If you want to cancel a number, you should use the cancel option. Do you want to continue and only remove this from the portal?");
        if (userConfirmed) {
            setPopupOpen(true);
            const response = await axiosJWT.post(`${baseUrl}/portal-number-remove`,{numberList: selectedItems, cid:selectedCompany}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setPopupOpen(false);
            fetchFilteredData();
            // window.location.reload();
        }
        
    }

    const getCustomerDigitMappingAccess = async () => {
        try {
            // setApiCallCount((prevCount) => prevCount + 1);
            const response = await axios.get(`${baseUrl}/check-company-e911-access/${cid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            console.log(response.data);
          
            setInventoryAccess(response.data.inventory_switch === 1 ? true : false);
            setMutipleInventoryAccess(response.data.multiple_inventory_switch === 1 ? true : false);
            if(response.data.cia_enable_switch === 1){
                if((role > 2 && response.data.cia_account_type == 1) || (role <= 2 && response.data.cia_account_type == 0)){
                    setCiaAccess(true);
                }else{
                    setCiaAccess(false);
                }
            }else{
                setCiaAccess(false);
            }
            setIsMappingAccessFetched(true);
        } catch (error) {
            // setLoading(false);
            
           console.log(error);
            
        }
    };

    const handleEditButtonClick = (e) => {
        e.preventDefault();
        navigate(`/edit-multiple-trunk-detail`, { state: {selectedItems: selectedItems, cid:selectedCompany}});
    }
    
    const handleCiaTypeCommon = async (ciaValue) => {

        const tnNumbers = selectedItems.map(item => item.tn);
        const type = selectedItems.length > 0 ? 1:2;
        let company_id;
        if(role <=2 ){
            company_id = selectedCompany;
        }else{
            company_id = cid;
        }
        const formData = {tnNumbers,ciaValue, type, company_id, status, selectedEnrmsType, selectedType, selectedCarrier, searchValue, selectedRoute}

        try{
            const response = await axios.post(`${baseUrl}/update-cas-type/`,{formData : formData}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }      
            });

            if(response.status == 200){
                fetchFilteredData();
                // window.location.reload()
            }

        }catch(error){
            console.log(error)
        }
    }  

    const fetchFilteredData = () => {
        const company_id = abx != null ? abx.selectedCompany : selectedCompany;
        const carrier_id = abx != null ? abx.selectedCarrier : selectedCarrier;
        const enrms_type = abx != null ? abx.selectedEnrmsType : selectedEnrmsType;
        const selected_type = abx != null ? abx.selectedType : selectedType;
        const status_value = abx != null ? abx.status : status;
        const route = abx != null ? abx.selectedRoute : selectedRoute;
        const search_value = abx != null ? abx.searchValue : searchValue;

        getOrder(page,company_id, search_value, status_value, route, carrier_id, selected_type, enrms_type);
    }
 
 
    return (
        <div className="panelbox">
           <Navbar  token={token} access = {access}/>
            <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
                <div className="content-page">
                    <div className="row">
                        <div className="col-6">
                            <div className="left-title">
                                <h1>Inventory</h1>
                            </div>
                        </div>
                        <div className="col-6 top-right-buttons">
                        <div className="export-btn newrow"><a className="newrow" onClick={handleExportCSV} href="#">Export</a></div>
                        {/* {hasPermission("callpassaccess") &&<div className="export-btn newrow"><a className="newrow" href="call-pass-settings">Call Pass Settings</a></div>} */}
                        {/* <div className="export-btn newrow"><a className="newrow"  href="bulk-number-change-route">Bulk Number Change Route</a></div> */}
                        <div className="export-btn addnew-button"><Link to="/number-management"><span>&larr;</span> Back</Link></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 order-filter">
                            <div className="boxinput">
                            
                            <input className="search-leave satff-listing-page-search" type="text" name="search" placeholder="Search" value={searchValue} onChange={handleSearchChange} />                      
                            <select className="form-control" name="usertype" value={status} onChange={handleUserTypeChange}>
                                 <option value="" >Status</option>                                    
                                 <option value="0">Active</option>
                                 <option value="1">Cancelled</option>
                                 <option value="2">Others</option>
                                
                             </select>
                             
                               {role <=2 && <select className="company-name form-control" name="company" value={selectedCompany} onChange={handleComapnyChange}>
                                    <option value={0} >Company</option>
                                    {company.map((comp, index) => (
                                      <option key={index} value={comp.id}>
                                        {comp.cname}
                                      </option>
                                    ))}
                                </select>
                                 }
                                {/* {role <=2 && selectedCompany != '0' && (<select className="form-control" name="usertype" value={selectedRoute} onChange={handleRouteOptionChange}>
                                 <option value="" >Route Option</option>                                    
                                 {routeList.map((route, index) => (
                                  <option key={index} value={route.value}>
                                    {route.value}
                                  </option>
                                ))}
                                
                             </select>)} */}
                             <select className="form-control" name="number_type" value={selectedType} onChange={handleTypeChange}>
                                 <option value="" >Number Type</option>                                    
                                 <option value="0">Did</option>
                                 <option value="2">Toll Free</option>
                                
                             </select>
                             {role <=2 && <select className="form-control" name="carrier_type" value={selectedCarrier} onChange={handleCarrierChange}>
                                 <option value="" >Carrier Type</option>                                    
                                 <option value="0">Inteliquent</option>
                                 <option value="1">Bandwidth</option>
                                 {carriers.filter((item) => !["Inteliquent", "Bandwidth"].includes(item.name)).map((item, index) => (
                                    <option key={index} value={item.carrier_index}>
                                    {item.name}
                                  </option>
                                 ))}
                                
                             </select>}
                             {ciaAccess && <select className="form-control" name="enrms_status" value={selectedEnrmsType} onChange={handleEnrmsTypeChange}>
                                 <option value="" >E-NRMS Type</option>                                    
                                 <option value="0">Disable</option>
                                 <option value="1">Enable</option>
                                
                             </select>}
                             {role >= 3 && (<select className="form-control" name="usertype" value={selectedRoute} onChange={handleRouteOptionChange}>
                                 <option value="" >Route Option</option>                                    
                                 {routeList.map((route, index) => (
                                  <option key={index} value={route.value}>
                                    {route.value}
                                  </option>
                                ))}
                                
                             </select>)}
                                <button  className="search-btn" onClick={handleSearchSubmit}>Search</button>
                               { (selectedCompany || status === 0 || status === "0" || status === "1" || status === "2" || searchValue || selectedRoute || selectedCarrier || selectedType || selectedEnrmsType) && (<a className='search-btn clear-btn' onClick={clearFilter} >Clear</a>)}
                            </div>
                        </div>
                        <div className="col-4 top-right-buttons">
                        
                        {mutipleInventoryAccess && userMultipleInventoryAccess && selectedItems.length > 0 && <div className="export-btn newrow"><a className="newrow" onClick={handleRemoveNumber} href="#">Remove</a></div>}
                        {status != 2 && mutipleInventoryAccess && userMultipleInventoryAccess && selectedItems.length > 0 && <div className="export-btn addnew-button"><a className='newrow' onClick={handleEditButtonClick}>Edit {selectedItems.length} Numbers</a></div>}
                        {role <= 2 && ciaAccess  && isFilter && <div className='export-btn addnew-button'><a href="#" className='newrow' onClick={() => {handleCiaTypeCommon(true)}}>{selectedItems.length > 0 ? 'Enable E-NRMS' : 'Enable E-NRMS for filtered Tel Number(s)'}</a></div>}
                        {role <= 2 && ciaAccess  && isFilter && <div className='export-btn addnew-button'><a href="#" className='newrow' onClick={() => {handleCiaTypeCommon(false)}}>{selectedItems.length > 0 ? 'Disable E-NRMS' : 'Disable E-NRMS for filtered Tel Number(s)' }</a></div>}

                        </div>
                    </div>
                    
                    <div className="common-white-shadow-background setting-section">
                        <div className="row">
                        <div className=" setting-section haft-whitebox">
                            {/* <div className="search-result-section inventory-table">
                                <h3></h3>
                               
                            </div> */}
                                <table className="tablebox search-result-table inventory-table">
                                    <thead>
                                        <tr>
                                            {(((mutipleInventoryAccess && userMultipleInventoryAccess) || ciaAccess) && isFilter ) && <th>  <input type="checkbox" className="newrow checkboxAll" onClick={handleSelectAll}  /></th>}
                                           
                                            <th>Tel Number</th>                                            
                                            {/* <th>Route Option</th> */}
                                            <th>Number Type</th>
                                            {role <=2 && <th>Carrier Type</th>}
                                            <th>CNAM</th>
                                            {ciaAccess && <th>E-NRMS Type</th>}
                                            {role <=2 && <th>Note</th>}
                                            <th>Created At({timeZone})</th>
                                            <th>Status</th>
                                           
                                            {/* <th>Confirmed Due Date</th> */}
                                            {/* <th>Created By</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {inventory.map((item, index) => (
                                        <tr key={index}>
                                             {(((mutipleInventoryAccess && userMultipleInventoryAccess) || ciaAccess) && isFilter ) && <td><input type="checkbox" onChange={() => handleCheckboxChange(item)}
    checked={selectedItems.length > 0 && selectedItems.some(tnObj => tnObj.tn === item.tn_number)}  /></td>}
                                        {/* <td><Link to={{ pathname: ``}} >{item.tn_number}</Link></td>   */}
                                        <td>{role  > 2 && status == 2 ? item.tn_number : <a href="#" onClick={(e) => handleNavigation(e, item.id, item.number_type)}>{item.tn_number}</a>}</td>
                                        {/* <td>{item.customer_name}</td> */}
                                        <td>{item.did_order == 2 ? "Toll Free" : (item.did_order == 0 ? "Did" : "-")}</td>
                                        {role <=2 &&<td>{item.carrier_name}</td>}
                                        <td>{item.cname_label ? item.cname_label : "-"}</td>
                                        {ciaAccess && <td>{item.cas_type == 1 ? 'Enabled' : (item.cas_type == 0 ? 'Disabled' : '-')}</td>}
                                        {role <=2 && <td>{item.note}</td>}
                                        <td>{formatDateTimeToPST(item.created_at, timeZone, role)}</td>
                                        <td>{item.status == 0 ? "Active" : (item.status == 1 ? "Cancelled" : (item.status == 2 ? 'Others' : "Pending"))}</td>
                                       
                                        {/* <td>{item.fname} {item.lname}</td> */}
                                        </tr>))}
                                        {/* <tr>
                                            <td>1571470</td>
                                            <td>TestZoliABS_2023_03_17_19_</td>
                                            <td>Disconnect</td>
                                            <td>04/14/2023</td>
                                            <td><span className="redtxt">Canceled</span></td>
                                            <td>00</td>
                                            <td>04/14/2023</td>
                                            <td>api_user</td>
                                        </tr> */}
                                         </tbody>
                                </table>
                                {/* <div className="recored-setup"> */}
                                    {/* <label for="cars">Records Per Page </label>

                                    <select name="cars" id="cars">
                                      <option value="volvo">1</option>
                                      <option value="saab">2</option>
                                      <option value="mercedes">3</option>
                                      <option value="audi">4</option>
                                    </select> */}
                                     {totalItem > 20 && (<Pagination
                                  activePage={page}
                                  itemsCountPerPage={20}
                                  totalItemsCount={totalItem}
                                  pageRangeDisplayed={5}
                                  onChange={handlePageChange.bind(this)}
                                />)}
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}
 
export default InventoryList