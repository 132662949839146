const sansayList = [
    {name:"LA1",  ip:'8.26.78.38'},
    {name:"LA2",  ip:'8.41.59.138'},
    {name:"SLC1", ip:'8.37.87.138'},
    {name:"SLC2", ip:'8.37.87.158'},
    {name:"MN1",  ip:'8.38.41.138'},
    {name:"MN2",  ip:'8.38.41.158'},
    {name:"TOR1", ip:'8.26.79.38'},
];
const currentDomain = window.location.hostname;

let apiUrl;
if(currentDomain === 'localhost'){
     apiUrl =  'http://localhost:5000'; 
}else{
     apiUrl =  `https://${currentDomain}/api`;
}

let schedulerUrl;
if(currentDomain === 'localhost'){
     schedulerUrl =  'http://localhost:4000/caller-dailing-schedual'; 
}else{
     schedulerUrl =  `https://${currentDomain}/caller_dailing/caller-dailing-schedual`;
}

const dayNameDisplay = (day) => {
     if(day === 1){
         return "Monday";
     }else if(day === 2){
         return "Tuesday";
     }else if(day === 3){
         return "Wednesday";
     }else if(day === 4){
         return "Thursday";
     }else if(day === 5){
         return "Friday";
     }else if(day === 6){
         return "Saturday";
     }else if(day === 7){
         return "Sunday";
     }
}

const reportTypes = [
    { value: "1", label: "E-NRMS"},
    { value: "2", label: "Basic NRMS" },
    { value: "3", label: "E-Only NRMS" },
];

const extensionRangeList = [
    { value: "Chase", label: "Chase"},
    { value: "Citi", label: "Citi" },
    { value: "IDOR", label: "IDOR" },
    { value: "BOA", label: "BOA"},
    { value: "EO", label: "EO" },
    { value: "Med", label: "Med" },
    { value: "Mau", label: "Mau"}
];


export {sansayList,currentDomain, apiUrl,schedulerUrl, dayNameDisplay, reportTypes, extensionRangeList};