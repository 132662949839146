/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import Header from "../Header";
import baseUrl from "../BaseUrl";
import Pagination from "react-js-pagination";
import { useNavigate, Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import formatDateTimeToPST from "../PSTDateConvert";
import Loader from "../Loader";
 
const ViewOrder = () => {
    const [name, setName] = useState('');
    const [timeZone, seTimeZone] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [orders, setOrders] = useState([]);
    const [error, setError] = useState(null);
    const [role, setRole] = useState('');
    const [access, setAccess] = useState([]);
    const [numberManagementAccess, setNumberManagementAccess] = useState(true);
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [selectedCompany, setSelectedCompany] = useState(0);
    const [company, setCompany] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [searchNumberValue, setSearchNumberValue] = useState('');
    const [status, setStatus] = useState("");
    const [searchDate, setSearchDate] = useState("");
    const [searchEndDate, setSearchEndDate] = useState("");
    const [displayDate, setDisplayDate] = useState("");
    const [displayEndDate, setDisplayEndDate] = useState("");
    const [popupOpen, setPopupOpen] = useState(false);
    const [type, setType] = useState("");
    
    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
        getUserAccess();
        // getOrder(1);
        getUserCompany();
    }, []);

    useEffect(() => {
        if(access.length > 0 && token){
            checkAccess(access, token);
        }
    }, [access, token, numberManagementAccess]);
    useEffect(() => {
        getOrder(page,selectedCompany, searchValue, status, type, searchDate, searchEndDate,searchNumberValue);
    }, [page]);
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded);
            setName(decoded.name);
            seTimeZone(decoded.timezone);
            setRole(decoded.role)
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }
    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            setNumberManagementAccess(response.data.number_management_switch == 0 ? false : true);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }

    const getUserCompany = async () => {
        
        const response = await axiosJWT.get(`${baseUrl}/active-user-company`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        const company = response.data;
        company.shift();
        // console.log(response.data);
        setCompany(company);
    }

    const handlePageChange  = (pageNumber) => {
        // console.log(`active page is ${pageNumber}`);
        setPage(pageNumber);
    }
    const getOrder = async (page, cid, search, status, type, date, enddate,number) => {
        // console.log(page);
        setPopupOpen(true);
        const response = await axiosJWT.get(`${baseUrl}/orders?page=${page}&limit=${20}&company=${cid}&search=${search}&status=${status}&type=${type}&date=${date}&enddate=${enddate}&number=${number}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log(response.data);
        setPopupOpen(false);
        setOrders(response.data.data);
        setTotalItem(response.data.totalItem);
    }
    const hasPermission = (permission) => {
        return access.some((item) => item.access === permission);
    };

    const handleComapnyChange = (e) => {
        setSelectedCompany(e.target.value);
        // setPage(1);
        // console.log(e.target.value);
        // getUsers(page,e.target.value, selectedUserType); // Reset page to 1 when filter changes
    };

    const clearFilter = () => {
        setStatus("");
        setType("");
        setSearchDate("");
        setSearchEndDate("");
        setDisplayDate("");
        setDisplayEndDate("");
        setSearchNumberValue("");
        setSelectedCompany(0);
        setSearchValue('');
        setPage(1);
        // getUsers(page,0, 0, '');
        getOrder(1,0,'', '', '', '', '', '');
    }

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    };
    const handleSearchNumberChange = (e) => {
        setSearchNumberValue(e.target.value);
    };
    const handleSearchDate = (date) => { 
        console.log(date);  
        const formattedDate =
        date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
         date.getDate().toString().padStart(2, '0');
      
         
        setSearchDate(formattedDate);
        setDisplayDate(date);
    };
    const handleSearchEndDate = (date) => { 
        console.log(date);  
        const formattedDate =
        date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
         date.getDate().toString().padStart(2, '0');
      
         
        setSearchEndDate(formattedDate);
        setDisplayEndDate(date);
    };
    const handleStatusChange = (e) => {
        setStatus(e.target.value);
        // setPage(1);
        // console.log(e.target.value);
        // getUsers(page,selectedCompany, e.target.value, ''); // Reset page to 1 when filter changes
    };
    const handleTypeChange = (e) => {
        setType(e.target.value);
        // setPage(1);
        // console.log(e.target.value);
        // getUsers(page,selectedCompany, e.target.value, ''); // Reset page to 1 when filter changes
    };
    const handleSearchSubmit = () => {
        setPage(1);
        getOrder(1,selectedCompany,searchValue, status, type, searchDate, searchEndDate,searchNumberValue);
        // getUsers(1, selectedCompany, selectedUserType, searchValue);
    };

    const checkAccess = async (access) => {
        if(!hasPermission('ordernewnumber') || !numberManagementAccess){
            navigate('/dashboard');
        }
    }
        

    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            seTimeZone(decoded.timezone);
            setRole(decoded.role)
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
 
 
    return (
        
        <div className="panelbox">
            {popupOpen && (
                     <Loader /> 
                ) }
           <Navbar  token={token} access = {access}/>
            <div className="right-panel">
            <Header name={name} />
            
                <div className="content-page">
                
                    <div className="row">
                        <div className="col-8">
                            <div className="left-title">
                                <h1>View Orders</h1>
                            </div>
                        </div>
                        <div className="col-4 top-right-buttons">                                        
                            <div className="export-btn addnew-button"><Link to="/number-management"><span>&larr;</span> Back</Link></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 order-filter transection-filter">
                            <div className="boxinput">
                            
                                <input className="search-leave satff-listing-page-search" type="text" name="search" placeholder="Search Order Id" value={searchValue} onChange={handleSearchChange} /> 
                                <input className="search-leave satff-listing-page-search" type="text" name="search" placeholder="Search tn number" value={searchNumberValue} onChange={handleSearchNumberChange} /> 
                                <select className="form-control orderType" name="usertype" value={type} onChange={handleTypeChange}>
                                     <option value="" >Order Type</option>                                    
                                     <option value="0">New</option>
                                     <option value="1">Change</option>
                                     <option value="2">Disconnect</option>
                                     <option value="3">Feature</option>
                                     <option value="4">New DMT</option>
                                     <option value="5">Delete DMT</option>
                                     <option value="6">Non-supported Carrier</option>
                                </select>  
                                {role <=2 && <select className="company-name form-control orderStatus" name="orderStatus" value={status} onChange={handleStatusChange}>
                                    <option value="">Select Internal order Status</option>
                                    <option value="0" >In Service</option>
                                    <option value="1" >Close</option>
                                    <option value="2" >Pending</option>
                                </select> }
                             
                                <DatePicker
                                    selected={displayDate}
                                    onChange={handleSearchDate}
                                    dateFormat="MM/dd/yyyy" // Set the desired date format
                                    placeholderText="From Date"
                                    className="from-date"
                                />
                                <DatePicker
                                    selected={displayEndDate}
                                    onChange={handleSearchEndDate}
                                    dateFormat="MM/dd/yyyy" // Set the desired date format
                                    placeholderText="To Date"
                                    className="end-date"
                                />     
                             
                                {role <=2 && <select className="company-name form-control" name="company" value={selectedCompany} onChange={handleComapnyChange}>
                                    <option value={0} >Company</option>
                                    {company.map((comp, index) => (
                                        <option key={index} value={comp.id}>
                                          {comp.cname}
                                        </option>
                                    ))}
                                </select>}
                                
                                <button  className="search-btn" onClick={handleSearchSubmit}>Search</button>
                               { (selectedCompany || status|| type || searchValue || searchDate || searchNumberValue || searchEndDate) && (<a className='search-btn clear-btn' onClick={clearFilter} >Clear</a>)}
                            </div>
                        </div>
                        
                    </div>
                   
                    <div className="common-white-shadow-background setting-section">
                        <div className="row">
                        <div className=" setting-section haft-whitebox">
                            <div className="search-result-section">
                                <h3></h3>
                                {/* <div className="export-btn newrow"><a className="newrow" href="#">Export</a></div> */}
                            </div>
                                <table className="tablebox search-result-table">
                                    <thead>
                                        <tr>
                                            <th>Customer Order ID</th>
                                            {role <= 2 && <th>Company</th>}
                                            <th>Order Type</th>
                                            {/* <th>Submit Date</th> */}
                                            
                                            <th>Quantity</th>
                                            <th>Order Date ({timeZone})</th>
                                            {role <= 2 &&<th>Overall Status</th>}
                                            <th>Total Number Success</th>
                                            <th>Total Number Failed</th>
                                            {role <= 2 &&<th>Internal Order Status</th>}
                                            {/* <th>Confirmed Due Date</th> */}
                                            <th>Created By</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {orders.map((order, index) => (
                                        <tr key={index}>
                                        <td><Link to={{ pathname: `/view-order-detail/${btoa(order.unique_id)}`}} >{order.unique_id}</Link></td>
                                        {role <= 2 && <td>{order.cname}</td>}
                                        <td>{order.order_type == 0 ? "New" : (order.order_type == 1 ? "Change" : (order.order_type == 2 ? "Disconnect" : (order.order_type === 3 ? "Feature" : (order.order_type === 4 ? 'New DMT' : (order.order_type === 5 ? 'Delete DMT' : "Non-supported Carrier")))))}</td>
                                        {/* <td></td> */}
                                        
                                        <td>{order.quantity}</td>
                                        {/* <td>{new Date(order.created_at).toLocaleDateString('en-US')}</td> */}
                                        <td>{formatDateTimeToPST(order.created_at, timeZone, role)}</td>
                                        {role <= 2 &&<td>{order.overall_status}</td>}
                                        <td>{order.success_count}</td>
                                        <td>{order.failed_count}</td>
                                        {role <= 2 &&<td>{order.order_status == 0 ? "In Service" : (order.order_status == 1 ? "Close" : "Pending")}</td>}
                                        <td>{order.fname} {order.lname}</td>
                                        </tr>))}
                                       
                                         </tbody>
                                </table>
                                
                                     {totalItem > 20 && (<Pagination
                                  activePage={page}
                                  itemsCountPerPage={20}
                                  totalItemsCount={totalItem}
                                  pageRangeDisplayed={5}
                                  onChange={handlePageChange.bind(this)}
                                />)}
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}
 
export default ViewOrder